import React from "react"
import AGBTherapeutInnenContent from "../components/AGBTherapeutInnenContent"
import Layout from "../components/Layout"
import Section from "../components/Section"

export interface AGBTherapeutInnenProps {}

const AGBTherapeutInnen: React.SFC<AGBTherapeutInnenProps> = () => {
  return (
    <Layout>
      <Section>
        <AGBTherapeutInnenContent />
      </Section>
    </Layout>
  )
}

export default AGBTherapeutInnen
