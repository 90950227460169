import { Link } from "gatsby"
import React from "react"
import { H1 } from "./text/H1"
import { H2 } from "./text/H2"
import { Paragraph } from "./text/Paragraph"

export interface AGBTherapeutInnenContentProps {}

const AGBTherapeutInnenContent: React.SFC<AGBTherapeutInnenContentProps> = () => {
  return (
    <>
      <H1 text="Allgemeine Geschäftsbedingungen - Therapeut*innen" />
      <H2 text="§ 1 Geltungsbereich" />
      <Paragraph>
        Die vorliegenden Nutzungsbedingungen gelten für sämtliche Angebote der
        Die Psychotherapie App UG (haftungsbeschränkt) i. L., Kroatengasse 3,
        97070, Würzburg (nachfolgend „DPTA“) in ihrer zum Zeitpunkt des
        Vertragsschlusses gültigen Fassung, die von der Therapeutin oder
        Therapeut (nachfolgend „Therapeut“) über die Internetseite
        app.diepsychotherapieapp.de von DPTA bezogen werden. Abweichungen
        bedürfen der Schriftform.
      </Paragraph>
      <Paragraph>
        Unter app.diepsychotherapieapp.de bietet DPTA den Patienten des
        Therapeuten, die psychotherapeutisch behandelt werden, einen digitalen
        Service an, der dem Therapeuten ermöglicht therapeutische Aufgaben im
        digitalen Format zu erstellen, seine Patienten zur App einzuladen und
        diesen die Aufgaben für ein festgelegtes Zeitfenster und Frequenz
        zuzuweisen. Seinen Patienten wird die Möglichkeit geboten, Ihre
        zugewiesenen Aufgaben im digitalen Format zu absolvieren. DPTA bietet
        hierfür die Plattform und technischen Funktionen in Form einer
        Web-Anwendung an.
      </Paragraph>
      <Paragraph>
        Sämtliche Angebote richten sich ausdrücklich nur an Therapeuten, die
        Unternehmer im Sinne von § 14 BGB sind. Unternehmer ist jede natürliche
        oder juristische Person oder eine rechtsfähige Personengesellschaft, die
        bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder
        selbständigen beruflichen Tätigkeit handelt. Unter dem
        Unternehmerbegriff fallen ausdrücklich auch Therapeuten, die in Ausübung
        ihrer freiberuflichen Tätigkeit handeln.
      </Paragraph>
      <H2 text="§ 2 Zustandekommen des Vertrages" />
      <Paragraph>
        Das Angebot für die von DPTA angebotenen Leistungen nimmt der Therapeut
        an, indem er sich auf app.diepsychotherapieapp.de mit Nutzernamen und
        Passwort registriert. Der Vertrag mit DPTA kommt zustande, wenn der
        Therapeut das Angebot von DPTA mit der Registrierung annimmt und dabei
        diesen AGB zustimmt, welche Voraussetzung für die Inanspruchnahme der
        von DPTA angebotenen Leistungen ist. Es kommt eine Nutzungsvereinbarung
        nach Maßgabe dieser Allgemeinen Geschäftsbedingungen zustande. <br />
        Es besteht kein Rechtsanspruch auf die Inanspruchnahme der Leistungen
        von DPTA und DPTA ist berechtigt, die Registrierung ohne Angabe von
        Gründen zu verweigern.
      </Paragraph>
      <Paragraph>
        DPTA behält es sich vor, für einzelne Leistungen ergänzende oder
        abweichende Bedingungen zu vereinbaren.
      </Paragraph>
      <H2 text="§ 3 Leistungsumfang; Zweckbestimmung" />
      <Paragraph>
        Zweck der von DPTA angebotenen Leistungen ist ausschließlich die
        Unterstützung der therapeutischen Behandlung durch Kommunikation und
        Datenspeicherung zur Vereinfachung der Therapie seiner Patienten. Bei
        sämtlichen von DPTA angebotenen Leistungen handelt es sich somit weder
        um eine medizinische oder ärztliche Beratung. Diese stellen auch keinen
        Ersatz für eine Untersuchung oder Behandlung durch einen Arzt oder
        Therapeuten dar. Dem Therapeuten ist bewusst, dass DPTA keine
        Verantwortung für fehlerhafte oder unvollständige ärztliche Beratung
        trägt. DPTA übernimmt keine Garantie für den Therapieerfolg. <br />
        Bei sämtlichen von DPTA angebotenen Leistungen handelt es sich nicht um
        ein Medizinprodukt im Sinne des Medizinproduktgesetz und der Richtlinie
        93/42/EWG über Medizinprodukte. Die Angebote von DPTA sind nicht
        CE-zertifiziert.
      </Paragraph>
      <H2 text="§ 4 Betrieb des Leistungsangebots" />
      <Paragraph>
        DPTA ist stets bemüht einen ordnungsgemäßen Betrieb der angebotenen
        Leistungen sicherzustellen, übernimmt jedoch keinerlei Verantwortung
        oder Haftung für die ununterbrochene Nutzbarkeit des Internet-Angebots
        und sämtlicher weiterer Programmleistungen sowie deren einzelnen
        Funktionen, insbesondere nicht für technisch bedingte Verzögerungen,
        Unterbrechungen und Ausfälle. Dem Therapeuten entstehen daraus keinerlei
        Ansprüche.
      </Paragraph>
      <Paragraph>
        Der Therapeut verpflichtet sich, seine Zugangsdaten zum Nutzungsprofil,
        insbesondere aber nicht ausschließlich das Passwort für den Zugang,
        geheim zu halten und vor dem Zugriff Dritter zu sichern sowie deren
        Kenntnisnahme durch Dritte weder zu dulden noch zu ermöglichen. Im Falle
        eines Missbrauchs oder Verlusts des Passworts oder eines entsprechenden
        Verdachts hat der Therapeut dies umgehend DPTA mitzuteilen.
      </Paragraph>
      <Paragraph>
        Eine Weitergabe der Inhalte der von DPTA angebotenen Leistungen sowie
        die Informationen auf der Internetseite www.diepsychotherapieapp.de ist
        ohne ausdrückliche Zustimmung in Textform von DPTA ausdrücklich
        untersagt.
      </Paragraph>
      <H2 text="§ 5 Preise; Nutzungsdauer" />
      <Paragraph>
        Für die Nutzung der von DPTA angebotenen Leistungen fallen keine Kosten
        an.
      </Paragraph>
      <Paragraph>
        Nach erfolgreicher Registrierung können die von DPTA angebotenen
        Leistungen unbefristet genutzt werden.
      </Paragraph>
      <H2 text="§ 6 Sperrung des Zugangs / Kündigung / Löschung der Daten" />
      <Paragraph>
        DPTA behält sich vor, bei Verdacht einer missbräuchlichen Nutzung oder
        wesentlichen Vertragsverletzung diesen Vorgängen nachzugehen,
        entsprechende Vorkehrungen zu treffen und bei einem begründeten Verdacht
        den Zugang des Therapeuten zu sperren. Sollte der Verdacht ausgeräumt
        werden können, wird die Sperrung wieder aufgehoben, andernfalls steht
        DPTA ein fristloses Kündigungsrecht zu.
      </Paragraph>
      <H2 text="§ 7 Kündigung / Löschung der Daten " />
      <Paragraph>
        Der Therapeut hat das Recht, die Löschung seines Nutzerkontos über den
        persönlichen Login-Bereich zu beantragen. Macht der Therapeut die
        Sperrung des Zugangs nicht rückgängig oder meldet er sich nicht
        innerhalb von 30 Tagen erneut mit seinen Zugangsdaten in sein
        Nutzerkonto an, endet das Vertragsverhältnis mit DPTA 30 Tage nach dem
        Sperrantrag des Therapeuten. Mit Ende des Vertragsverhältnisses löscht
        DPTA den gesamten Account des Therapeuten und alle darin gespeicherten
        Daten seiner Patienten unwiderruflich. Meldet sich der Therapeut länger
        als sechs Monate nicht in seinem Nutzerkonto an, so endet das
        Vertragsverhältnis mit DPTA. Mit Ende des Vertragsverhältnisses löscht
        DPTA den gesamten Account des Therapeuten und alle darin gespeicherten
        Daten seiner Patienten unwiderruflich.
      </Paragraph>

      <H2 text="§ 8 Haftung" />
      <Paragraph>
        Schadensersatzansprüche des Therapeuten sind ausgeschlossen, soweit
        nachfolgend nichts anderes bestimmt ist. Der vorstehende
        Haftungsausschluss gilt auch zugunsten der gesetzlichen Vertreter,
        Angestellten, Arbeitnehmer, Organe und Erfüllungsgehilfen von DPTA.
      </Paragraph>
      <Paragraph>
        Von dem Haftungsausschluss ausgenommen sind Schadensersatzansprüche
        jeglicher Art aufgrund einer Verletzung des Lebens, des Körpers, der
        Gesundheit und Schadensersatzansprüche aus der Verletzung wesentlicher
        Vertragspflichten. Wesentliche Vertragspflichten sind solche, deren
        Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist. Von dem
        Haftungsausschluss ebenfalls ausgenommen ist die Haftung für Schäden,
        die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von
        DPTA beruhen. <br />
        Bei fahrlässig verursachten Sach- und Vermögensschäden haftet DPTA nur
        bei der Verletzung einer wesentlichen Vertragspflicht, jedoch der Höhe
        nach beschränkt auf die bei Vertragsschluss vorhersehbaren und
        vertragstypischen Schäden.
      </Paragraph>

      <H2 text="§ 9 Informationspflichten" />
      <Paragraph>
        Alle weiteren Informationen zu DPTA, dem Angebot und der Abwicklung kann
        aus den Darstellungen über die Internetseite diepsychotherapieapp.de von
        DPTA entnommen werden. Bezüglich der technischen Schritte zum
        Vertragsschluss ist § 2 und § 3 zu beachten. Seine Eingaben kann der
        Therapeut während der Registrierung jederzeit abbrechen und korrigieren.
        Die für den Vertragsabschluss zur Verfügung stehende Sprache ist
        ausschließlich Deutsch. Der Therapeut kann alle im Zusammenhang mit dem
        Vertragsschluss erforderlichen Informationen, insbesondere die AGB in
        ihrer jeweils geltenden Fassung (vgl. § 11 der AGB) jederzeit innerhalb
        seines persönlichen Login-Bereichs unter dem Reiter “Einstellungen”,
        Unterpunkt “Rechtliches und Datenschutz” abrufen, kopieren, abspeichern
        und auszudrucken.
      </Paragraph>

      <H2 text="§ 10 Datenschutz" />
      <Paragraph>
        Neben den vorliegenden AGB gelten zusätzlich die Datenschutzbestimmungen
        von DPTA, die vom Kunden jederzeit über die{" "}
        <Link
          className="text-primary-600 hover:text-primary-800 no-underline"
          to="/datenschutzerklaerung"
        >
          Webseite
        </Link>{" "}
        abgerufen werden können.
      </Paragraph>

      <H2 text="§ 11 Urheberrechtshinweis" />
      <Paragraph>
        Sämtliche auf dieser Webseite und den E-Mails, sowie im Rahmen
        sämtlicher weiterer von DPTA angebotenen Leistungen bereitgestellten
        Texte, Softwares, Bilder, Grafiken, Videos, Tondokumente, Designs,
        Quellcodes o. ä., sind urheberrechtlich geschützt. Jegliche unbefugte
        Weiterverwendung hiervon bedarf der ausdrücklichen Zustimmung in
        Textform von DPTA.
      </Paragraph>

      <H2 text="§ 12 Änderungsvorbehalt" />
      <Paragraph>
        DPTA behält sich vor, die AGB jederzeit ohne Angabe von Gründen mit
        Wirkung für die Zukunft zu ändern und anzupassen. Die geänderten AGB
        werden dem Therapeuten in seinem persönlichen Login-Bereich angezeigt.
        Die weitere Nutzung des Leistungsangebots ist nur möglich, wenn der
        Therapeut den geänderten AGB zugestimmt hat. <br />
        Stimmt der Therapeut den geänderten AGB nicht zu, so gilt dies als
        Wiederspruch. Bei einem solchen Widerspruch steht DPTA ein fristloses
        Kündigungsrecht zu, wobei bereits geleistete Zahlungen anteilig im
        Verhältnis zur Restlaufzeit erstattet werden.
      </Paragraph>

      <H2 text="§ 13 Anwendbares Recht und Gerichtsstand" />
      <Paragraph>
        Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des
        UN-Kaufrechts.
      </Paragraph>

      <H2 text="§ 14 Salvatorische Klausel" />
      <Paragraph>
        Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder
        undurchführbar sein oder nach Vertragsschluss unwirksam oder
        undurchführbar werden, so wird dadurch die Wirksamkeit des Vertrages im
        Übrigen nicht berührt. An diese Stelle der undurchführbaren oder
        unwirksamen Bestimmung soll diejenige wirksame oder durchführbare
        Regelung treten, deren Wirkungen der wirtschaftlichen Zielsetzung am
        nächsten kommen, welche DPTA und der Therapeut mit der unwirksamen bzw.
        undurchführbaren Bestimmung verfolgt haben. Die vorstehenden
        Bestimmungen gelten entsprechend für den Fall, dass sich die Bedingungen
        als lückenhaft erweisen.
      </Paragraph>
    </>
  )
}

export default AGBTherapeutInnenContent
